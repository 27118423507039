<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">Polls Logs</div>
    <div class="
            col-lg-8 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
    </div>
  </div>
  <div class="custom-ultima-datatable">
    <DataTable :value="campaignLogList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :loading="loading"
      dataKey="ah1">
      <template #empty>No Records found.</template>
      <template #loading>Loading data. Please wait...</template>
      <Column field="name" header="Citizen Name" headerStyle="width: 25%" bodyStyle="width: 25%">
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.ai4 ? data.ai4 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="votermobile" header="Mobile No" headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div>
            {{ data.ai5 ? data.ai5 : "N/A" }}
          </div>
        </template>
      </Column>
      <Column field="statussent" header="Sent" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.ai11 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-if="data.ai11 == 0" style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusdeliverd" header="Deliverd" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.ai12 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-if="data.ai12 == 0" style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusread" header="Read" headerStyle="width: 10%" bodyStyle="width: 10%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <i class="pi pi-check-circle text-success" v-if="data.ai13 == 1" style="fontsize: 1rem"></i>
            <i class="pi pi-times-circle text-danger" v-if="data.ai13 == 0" style="fontsize: 1rem"></i>
          </div>
        </template>
      </Column>
      <Column field="statusbutton" header="Button Name" headerStyle="width: 30%" bodyStyle="width: 30%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <span class="me-4" v-if="data.ai6 == 1">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.ai6 == 1"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-if="data.ai6 != 1"></i>
              <span>{{ data.ai7 }}</span></span>
            <span class="me-4" v-if="data.ai6 == 2">
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem"></i>
              <span>{{ data.ai8 }}</span></span>
            <span v-if="data.ai6 == 3">
              <i class="pi pi-check-circle text-success me-2" style="fontsize: 1rem" v-if="data.ai6 == 3"></i>
              <i class="pi pi-times-circle text-danger me-2" style="fontsize: 1rem" v-if="data.ai6 != 3"></i>
              <span>{{ data.ai9 }}</span></span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
export default {
  data() {
    return {
      campaignLogList: [
        {
          id: 0,
          ai4: "Sunil Mohal",
          ai5: "9884673838",
          schemedate: "30/06/2023",
          schemesentto: "2,39,874",
          schemecontent: "test",
          disabledflag: 1,
          ai11: 1,
          ai12: 1,
          ai13: 1,
          ai6: 1,
          ai7: "Yes",
          ai8: "No",
          ai9: "Maybe"
        },
        {
          id: 4,
          ai4: "Rajesh Kumar",
          ai5: "7484676838",
          schemedate: "08/02/2022",
          schemesentto: "2,39,874",
          schemecontent: "test",
          disabledflag: 1,
          ai11: 1,
          ai12: 0,
          ai13: 0,
          ai6: 0,
          ai7: "Yes",
          ai8: "No",
          ai9: "Maybe"
        },
        {
          id: 4,
          ai4: "Tukaram Jadhav",
          ai5: "7973929374",
          schemedate: "08/02/2022",
          schemesentto: "2,39,874",
          schemecontent: "test",
          disabledflag: 1,
          ai11: 1,
          ai12: 1,
          ai13: 1,
          ai6: 2,
          ai7: "Yes",
          ai8: "No",
          ai9: "Maybe"
        },
      ],
      totalRecords: 2,
      loading: false,
      ApiLoadingSwitch: true,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
  },
  methods: {

  },
};
</script>